import React, { useState, useEffect } from "react";

import CustomDialog from "../../../../components/UI/CustomDialog/CustomDialog";
import { IOffer, ISignature } from "../../../../interfaces";
import { Alert, Row, Col } from "reactstrap";
import { isValidEmail, stringToDate } from "../../../../shared/utility";
import { getOfferSigners } from '../../../../shared/offer-utils';

import { EPageView, ESignerType } from "../../../../enums";

import SelfSendEmail from '../../components/Offers/Offer/OfferSendDialog/SelfSendEmail';

import classes from "../../components/Offers/Offer/OfferSendDialog/OfferSendDialog.module.scss";

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSend: () => void;
  onSelfSend: () => void;
  offer: IOffer;
  isValid: boolean;
  history:any;
  viewType:EPageView;
  onCancel: () => void;
  onSelfSendOk: () => void;
  onSelfClose?: () => void;
}

const OfferSendDialog: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  onSend,
  onSelfSend,
  offer,
  isValid,
  history,
  viewType,
  onCancel,
  onSelfSendOk,
  onSelfClose,
}) => {
  const [isSelfSendOpen, setIsSelfSendOpen]=useState<boolean>(false);

  const [signatures, setSignatures] = useState([]);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const cancelDialog = () => {
    closeDialog();
    onCancel();
  }

  const onOk = () => {
    onSend();
  };

  let content = null;
  let okButtonText = null;

  if (isValid) {
    content = (
      <React.Fragment>
        You are sending proposal to{" "}
        {/*<span className="text-danger">{offer.customer.email}</span>*/}
        {signatures.length == 0
          ? <span className="text-danger">{offer.customer.email}</span>
          : signatures.map((s,index)=>(<Row key={index} className={classes.border}><Col>{s.name}</Col><Col>{s.email}</Col></Row>)) }

        {(getOfferSigners(offer).length === 0) && offer.recipients && (
          <React.Fragment>
            <p className={classes.OtherRecipients}>Other recipients</p>
            {offer.recipients.map((recipient, i) => (
              <div key={i}>- {recipient}</div>
            ))}
          </React.Fragment>
      )}
        {viewType===EPageView.SENDEMAIL &&
        <SelfSendEmail isOpen={viewType===EPageView.SENDEMAIL} cancel={()=>setIsSelfSendOpen(false)} offer={offer} onOk={onSelfSendOk} onClose={onSelfClose} />}

      </React.Fragment>
    );
    okButtonText = "Send from C2C mail";
  } else {
    content = (
      <React.Fragment>
        {offer.customer && offer.customer.email && isValidEmail(offer.customer.email) ? null : (
          <Alert className={classes.ErrorMessage} color="danger">
            <p>Customer email is missing or invalid.</p>
          </Alert>
        )}

        {offer.customer && offer.customer.name ? null : (
          <Alert className={classes.ErrorMessage} color="danger">
            <p>Customer name is missing.</p>
          </Alert>
        )}

        {offer.userTemplate ? null : (
          <Alert className={classes.ErrorMessage} color="danger">
            <p>Template is missing.</p>
          </Alert>
        )}
        {offer.expirationDate && (stringToDate(offer.expirationDate) > new Date()) ? null : (
          <Alert className={classes.ErrorMessage} color="danger">
            <p>Expiration date is missing or invalid.</p>
          </Alert>
        )}
        <p>Please check the proposal and try again.</p>
      </React.Fragment>
    );
    okButtonText = "OK";
  }
  
  const copyPasteEmail = () => {
    //onSelfSend();
    setIsSelfSendOpen(true);
    onSelfSend();
  }

  //const extraButton:any=offer.link?{onClick:copyPasteEmail, disabled:false, label:"Copy to Email"}:null;
  const extraButton:any={onClick:copyPasteEmail, disabled:false, label:"Copy to send from elsewhere"};

  useEffect(()=>{
    setSignatures(getOfferSigners(offer).filter((ob:ISignature)=>ob.signerType!==ESignerType.USER));
  },[offer])

  return (<>
    {viewType==EPageView.SENDEMAIL
      ?<SelfSendEmail isOpen={viewType===EPageView.SENDEMAIL} cancel={()=>setIsSelfSendOpen(false)} offer={offer} onOk={onSelfSendOk} onClose={onSelfClose} />

      :<CustomDialog
      loading={false}
      onOk={isValid ? onOk : closeDialog}
      okButtonText={okButtonText}
      onCancel={isValid ? cancelDialog : null}
      open={isOpen}
      title="Send proposal"
      isValid={true}

      
      extraButton={(isValid && signatures.length == 0) ? extraButton : null }

    >
      {/*extraButton={isValid ? extraButton : null }*/}
    
      {content}
    </CustomDialog>}
  </>);
};

export default OfferSendDialog;
