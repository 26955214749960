import { addDays } from "date-fns";
import update from "immutability-helper";
import _ from "lodash";
import * as qs from "query-string";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
//import { Prompt, RouteComponentProps } from "react-router";
//import { RouteComponentProps } from "react-router";
import { RouteComponentProps } from '../../../../../withRouter';

import { useParams } from "react-router-dom";

import { Alert, Spinner } from "reactstrap";
import uuid from "uuid";
import ContentWithSidebarStatic from "../../../../../components/UI/ContentWithSidebarStatic/ContentWithSidebarStatic";
import CustomDialog from "../../../../../components/UI/CustomDialog/CustomDialog";
import FooterFixed from "../../../../../components/UI/FooterFixed/FooterFixed";
import SidebarStatic from "../../../../../components/UI/SidebarStatic/SidebarStatic";
import { EOfferStatus, EPageView, EUserTemplateBlockType, ESignerType } from "../../../../../enums";
import { IAppState, IBlock, IBlockContent, IBlockItem, ICustomer, IOffer, IOfferComment, IOrganization, IUser, IUserTemplate, IUserTemplateAreaBlock, IProduct, ISignature } from "../../../../../interfaces";
import { defaultOffer, isOfferDone } from "../../../../../shared/offer-utils";
import { dateToString, isValidEmail, stringToDate } from "../../../../../shared/utility";
import * as actions from "../../../../../store/actions";
import OfferBlockItemModal, { defaultBlockItemModal } from "../../../components/Offers/Offer/OfferBlockItemModal/OfferBlockItemModal";
import OfferCommentsContext from "../../../components/Offers/Offer/OfferComment/OfferCommentsContext";
import OfferEdit from "../../../components/Offers/Offer/OfferEdit/OfferEdit";
import OfferFooter from "../../../components/Offers/Offer/OfferFooter/OfferFooter";
import OfferMessagingSidebar from "../../../components/Offers/Offer/OfferMessagingSidebar/OfferMessagingSidebar";
import OfferPreview from "../../../components/Offers/Offer/OfferPreview/OfferPreview";
import OfferPreviewSidebar from "../../../components/Offers/Offer/OfferPreviewSidebar/OfferPreviewSidebar";
import OfferSendDialog from "../../../components/Offers/Offer/OfferSendDialog/OfferSendDialog";
import { EBlockEditingStyle } from "../../Blocks/Block/Block";
import OfferContext from "./OfferContext";

import { getOfferSigners } from '../../../../../shared/offer-utils';

//import { copyEmailToClipboard } from "../../../../../shared/utility";

// import classes from "./Offer.module.scss";

interface IStateProps {
  customers: Array<ICustomer>;
  customersLoading: boolean;
  customer: ICustomer;
  userTemplates: Array<IUserTemplate>;
  userTemplatesLoading: boolean;
  offersLoading: boolean;
  offersError: string;
  organization: IOrganization;
  commentLoading: boolean;
  commentError: string;
  currentUser: IUser;
  emailLoading: boolean;
}

interface IDispatchProps {
  onFindCustomers: () => Promise<Array<ICustomer>>;
  onFindUserTemplates: () => Promise<Array<IUserTemplate>>;
  onFindBlockItems: (id: string) => Promise<Array<IBlockItem>>;
  onGetBlockItem: (id: string) => Promise<IBlockItem>;
  onSaveOffer: (offer: IOffer) => Promise<IOffer>; //<boolean>;
  onUpdateOffer: (id: string, offer: IOffer, params: string) => Promise<IOffer>; //<boolean>;
  onGetOffer: (id: string) => Promise<IOffer>;
  onSaveBlockItem: (blockId: string, blockITem: IBlockItem) => Promise<IBlockItem>;
  onGetBlock: (id: string) => Promise<IBlock>;
  onGetUserOrganization: () => Promise<IOrganization>;
  onMarkOfferAccepted: (data: Object) => Promise<IOffer>;
  onCommentOffer: (data: Object) => Promise<IOffer>;
  onArchiveOffer: (id: string, archived: boolean) => Promise<IOffer>;
  onSaveCustomer: (customer: ICustomer) => Promise<ICustomer>;
  onDeleteOffer: (id: string) => Promise<boolean>;
  onCopyOffer: (offer: IOffer) => Promise<IOffer>;
  onTerminateOffer: (data: Object) => Promise<IOffer>;

  onSearchCustomers: (queryString: string) => void;
  onGetCustomer: (id: string) => Promise<ICustomer>;
}

interface IParams {
  id: string;
  type: EPageView;
}

interface IProps
  extends RouteComponentProps, //<IParams>,
    IStateProps,
    IDispatchProps {}

const defaultConfirmDialog = {
  isOpen: false,
  onOk: () => {},
  title: "",
  content: ""
};

interface IOfferBlockItems {
  [propertyName: string]: IBlockItem;
}

/**
 * Tää on aika kauhee sotku, pitää miettiä miten sais tehtyä paremmin.
 *
 */
const Offer: React.FC<IProps> = ({
  organization,
  customers,
  customersLoading,
  userTemplates,
  userTemplatesLoading,
  offersLoading,
  commentError,
  commentLoading,
  currentUser,
  onFindCustomers,
  onFindUserTemplates,
  onFindBlockItems,
  onGetBlockItem,
  onGetOffer,
  onGetBlock,
  onSaveOffer,
  onUpdateOffer,
  onGetUserOrganization,
  onMarkOfferAccepted,
  onCommentOffer,
  history,
  offersError,
  onArchiveOffer,
  onSaveCustomer,
  emailLoading,
  onDeleteOffer,
  onCopyOffer,
  location,
  onTerminateOffer,
  onSaveBlockItem,

  onSearchCustomers,
  onGetCustomer,
}) => {
  const params=useParams();

  const id:string = params.id;
  const type:EPageView = params.type as EPageView;

  const view = ((): string => {
    const params = qs.parse(location.search);
    return params.view as string;
  })();

  const isUpdate = id !== "add";

  const [pageView, setPageView] = useState(type);
  const [offer, setOffer] = useState<IOffer>(defaultOffer);

  const [initDone,setInitDone]=useState<boolean>(false);

  const setOfferAfterInit = (o:IOffer) => {
    if(initDone) {
      setOffer(o);
    }
  }

  const [offerBlockItems, setOfferBlockItems] = useState<IOfferBlockItems>({});
  const [blockItemsLoading, setBlockItemsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [blockItemModal, setBlockItemModal] = useState(defaultBlockItemModal);
  const [customerHasChanged, setCustomerHasChanged] = useState(false);
  const [saved, setSaved] = useState(false);
  const [hideEditableContent, setHideEditableContent] = useState(false);
  const [blockLoading, setBlockLoading] = useState("");

  /**
   * Convert blocks array to object with id as param name
   * @param userTemplate
   */
  const getBlocksObject = (
    userTemplate: IUserTemplate,
    blockType: EUserTemplateBlockType
  ): { [id: string]: IUserTemplateAreaBlock } => {
    const { header, main, footer } = userTemplate;
    //const { main } = userTemplate;
    return _.concat([], header.blocks, main.blocks, footer.blocks)
    //return main.blocks
      .filter((block:any) => block && block.blockType === blockType)
      .reduce((current: any, item:any) => {
        current[item.id] = item;
        return current;
      }, {});
  };

  const fetchBlockItemsWithContent = async (blockItemIds: Array<string>) => {
    let newOfferBlockItems = { ...offerBlockItems };
    const blockItems = await Promise.all(
      blockItemIds.map(async blockItemId => {
        let blockItem = newOfferBlockItems[blockItemId];
        if (!blockItem) {
          blockItem = await onGetBlockItem(blockItemId);
        }
        newOfferBlockItems = {
          ...newOfferBlockItems,
          [blockItemId]: blockItem
        };
        return blockItem;
      })
    );
    setOfferBlockItems(newOfferBlockItems);
    return blockItems;
  };


/*
  // this works
  const timer = useRef(null);

  const timerRef = useRef(null);
  useEffect(()=>{
    timerRef.current = offer;
  },[offer])

  const saveNow = () => {
    console.log(timerRef.current);
  }

  const resetTimer = () => {
    console.log("reset");
    clearTimeout(timer.current);
    timer.current=setTimeout(saveNow,5000);
  }

  useEffect(()=>{
    resetTimer();
    window.onmousemove=resetTimer;
    window.onkeypress=resetTimer;
    return () => clearTimeout(timer.current);
  },[]);

*/


  /**
   * Vähän tyhmä, mutta tällä saadaan helpoiten piilotettua kun select avautuu niin ylimääräinen harmaa taustasta.
   */
  useEffect(() => {
    if (type === EPageView.EDIT) {
      document.body.style.backgroundColor = "#FFFFFF";
    } else {
      document.body.style.backgroundColor = "#ECECEC";
    }
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [type]);

  useEffect(() => {
    if (isUpdate) {
      // edit old proposal
      (async () => {
        setInitLoading(true);
        const offer = await onGetOffer(id);
        if (!offer) {
          setInitLoading(false);
          return;
        }

        if (!offer.expirationDate) {
          offer.expirationDate = dateToString(addDays(new Date(), 30));
        }

        setEdit(offer.status === EOfferStatus.DRAFT);

        /** TODO: only call when in edit mode
         * Moved here because offers can be edited after status changes from draft
         * and templates and customers were empty in some cases.
         */
        const [userTemplates, customers, organization] = await Promise.all([
          onFindUserTemplates(),
          [], //onFindCustomers(),
          onGetUserOrganization()
        ]);


        /*
        if(offer.userTemplate) {
          console.log("ÄÄÄ",offer.userTemplate.settings.temporary);
          if(offer.userTemplate.settings.temporary) {
            const userTemplate = userTemplates.find(
              item => item.id === offer.userTemplate.id
            );

            setOffer({...offer,userTemplate:userTemplate});
          setInitLoading(false);

            return;

          }

        }
         */

        /**
         * Merges offerBlocksItems to userTemplate maybe only needed if draft.
         * When Edit we need to add more logic to be able to select updated userTemplate if needed.
         */

        if (offer.status === EOfferStatus.DRAFT && offer.userTemplate && !offer.userTemplate.initialized) {
          const userTemplate = userTemplates.find(
            item => item.id === offer.userTemplate.id
          );

          if (userTemplate) {
            const cloneUserTemplate = _.cloneDeep(userTemplate);
            const dynamicBlocks = getBlocksObject(
              cloneUserTemplate,
              EUserTemplateBlockType.DYNAMIC
            );
            const dynamicOfferBlocks = getBlocksObject(
              offer.userTemplate,
              EUserTemplateBlockType.DYNAMIC
            );

            /**
             * We need to fetch selected blockItems content
             */
            let blockItemIds: Array<string> = [];
            for (let key in dynamicOfferBlocks) {
              const offerBlock = dynamicOfferBlocks[key];

              if (offerBlock.offerBlockItems) {
                blockItemIds = _.union(
                  blockItemIds,
                  offerBlock.offerBlockItems
                    .filter(offerBlockItem => !offerBlockItem.editOnOffer)
                    .map((offerBlockItem: IBlockItem) => offerBlockItem.id)
                );
              }
            }
            // Fetches blocks items
            const blockItems = await fetchBlockItemsWithContent(blockItemIds);

            for (let key in dynamicBlocks) {
              const offerBlock = dynamicOfferBlocks[key];

              if (offerBlock && offerBlock.offerBlockItems) {
                dynamicBlocks[
                  key
                ].offerBlockItems = offerBlock.offerBlockItems.map(
                  (blockItem: IBlockItem) => {
                    return blockItem.editOnOffer
                      ? blockItem
                      : blockItems.find(
                          (item: IBlockItem) => item && item.id === blockItem.id
                        ); // sets fetched data to block
                  }
                );
              }
            }

            // Update simple blocks, maybe done wrong way beause if block is deleted wrong userTemplate item disappeares
            const simpleBlocks = getBlocksObject(
              cloneUserTemplate,
              EUserTemplateBlockType.SIMPLE
            );
            const simpleOfferBlocks = getBlocksObject(
              offer.userTemplate,
              EUserTemplateBlockType.SIMPLE
            );

            for (let key in simpleOfferBlocks) {
              const offerBlock = simpleOfferBlocks[key];
              const block = simpleBlocks[key];

              if (offerBlock && offerBlock.content && block && block.content) {
                for (let content of offerBlock.content) {
                  if (content.editOnOffer || content.contentType==="PRODUCTS" || content.contentType === "PRIVATE2") {
                    const indexOf = block.content.findIndex(
                      item => content.id === item.id
                    );
                    if (indexOf !== -1) {
                      block.content[indexOf] = content;
                    }
                  }
                }
              }
            }

            //offer.userTemplate = cloneUserTemplate;
            offer.userTemplate = {...cloneUserTemplate,settings:{...offer.userTemplate.settings,temporary:offer.userTemplate.settings.temporary}};

          }
          if(offer.customer) {
            offer.customer = {
//              ...customers.find(customer => customer.id === offer.customer.id),
              ...customers.find(customer => ((customer.id && customer.id === offer.customer.id) || (customer.pipedrivePersonId && String(customer.pipedrivePersonId) === String(offer.customer.pipedrivePersonId)) )),
              ...offer.customer,
              language: offer.customer.language
            };
          }
          offer.organization = organization;
        }


        setInitLoading(false);
        setOffer({ ...defaultOffer, ...offer });
        setInitDone(true); 

      })();
    } else {
      // new proposal
      (async () => {
        setInitLoading(true);
        setEdit(true);

        setInitDone(true);

        const [, , organization] = await Promise.all([
          onFindUserTemplates(),
          [], //onFindCustomers(),
          onGetUserOrganization()
        ]);

        if (offer) {
          if (!offer.expirationDate)
            offer.expirationDate = dateToString(addDays(new Date(), 30));
          if (organization) offer.organization = organization;
          setOffer({ ...defaultOffer, ...offer });
        }

        setInitLoading(false);
      })();
    }
    // eslint-disable-next-line
  }, [id, onGetOffer, setOffer, onFindUserTemplates, isUpdate]);

  useEffect(() => { // BUGME
    if (offer.userTemplate  && (!offersLoading && !initLoading)) {
      onUserTemplateChanged(offer.userTemplate);
    }
    // eslint-disable-next-line
  }, [offer.id, offer.userTemplate]); // && offer.userTemplate.id]);

  useEffect(() => {
    if (organization && offer.organization===null) {
      const newOffer = { ...offer };
      newOffer.organization = organization;
      setOffer(newOffer);
    }
    // eslint-disable-next-line
  }, [organization]);

  const viewClickHandler = (type: EPageView) => {
    setPageView(type);
    history.push(`/offers/${id}/${type}`);
    window.scrollTo(0, 0);
  };

  const onDynamicBlockItemChanged = async (
    blockId: string,
    blockItems: Array<IBlockItem>
  ) => {
    return new Promise<void>(async resolve => {
      const fetchBlockItems: Array<IBlockItem> = [];
      const editOnOfferBlockItems: Array<IBlockItem> = [];
      blockItems.forEach(blockItem => {
        if (blockItem.editOnOffer) {
          editOnOfferBlockItems.push(blockItem);
        } else {
          fetchBlockItems.push(blockItem);
        }
      });

      const blockItemsWithContent = await fetchBlockItemsWithContent(
        fetchBlockItems.map(blockItem => blockItem.id)
      );
      const newOffer = { ...offer };
      const blocks = getBlocksObject(
        newOffer.userTemplate,
        EUserTemplateBlockType.DYNAMIC
      );
      const block = blocks[blockId];

      if (block) {
        let newOfferBlockItems: Array<IBlockItem> = [];
        // this forces the order to be same
        blockItems.forEach(blockItem => {
          let addOfferBlockItem;
          if (blockItemsWithContent) {
            addOfferBlockItem = blockItemsWithContent.find(
              blockItemWithContent => blockItemWithContent.id === blockItem.id
            );
          }

          if (!addOfferBlockItem) {
            addOfferBlockItem = editOnOfferBlockItems.find(
              editOnOfferBlockItem => editOnOfferBlockItem.id === blockItem.id
            );
          }

          if (addOfferBlockItem) {
            newOfferBlockItems.push({ ...addOfferBlockItem });
          }
        });
        /*
        if (blockItemsWithContent) {
          newOfferBlockItems = [
            ...newOfferBlockItems,
            ...blockItemsWithContent
          ];
        }
        if (editOnOfferBlockItems) {
          newOfferBlockItems = [
            ...newOfferBlockItems,
            ...editOnOfferBlockItems
          ];
        }
        */
        block.offerBlockItems = newOfferBlockItems;
      }

      setOffer(newOffer);
      resolve();
    });

  };

  const onSimpleContentChanged = (
    block: IUserTemplateAreaBlock,
    blockContent: IBlockContent
  ) => {
    const newOffer = { ...offer };
    const blocks = getBlocksObject(
      newOffer.userTemplate,
      EUserTemplateBlockType.SIMPLE
    );
    try {
      const newBlock = blocks[block.id];
      const indexOf = newBlock.content.findIndex(
        item => item.id === blockContent.id
      );
      if (indexOf !== -1) {
        newBlock.content[indexOf] = { ...blockContent, editOnOffer: true };
      }
    } catch(err) {
      //console.log("ERROR",err);
    }
    setOffer(newOffer);
  };

  const getBlocks = (userTemplate: IUserTemplate) => {
    if (!userTemplate) return null;
    const blocks = [];
    const { header, main, footer } = userTemplate;
    //const { main } = userTemplate;
    if (header.blocks) blocks.push(...header.blocks);
    if (main.blocks) blocks.push(...main.blocks);
    if (footer.blocks) blocks.push(...footer.blocks);
    return blocks;
  };

  /**
   * TODO Pakko fiksaa paremmaks
   */
  const onUserTemplateChanged = async (userTemplate: IUserTemplate) => {
    if (userTemplate) {
      const newOffer = update(offer, {
        userTemplate: { $set: userTemplate },
      });

      const blocks = getBlocks(newOffer.userTemplate);
      if (blocks) {
        const blockIds = Array.from(
          new Set(
            blocks
              .filter(
                block => block.blockType === EUserTemplateBlockType.DYNAMIC
              )
              .map(block => block.blockId)
          )
        );
        setBlockItemsLoading(true);
        if (blockIds) {
          const blockItems = await Promise.all(
            blockIds.map(async blockId => {
              return { blockId, blockItems: await onFindBlockItems(blockId) };
            })
          );

          blocks.forEach(block => {
            const item = blockItems.find(
              item => item.blockId === block.blockId
            );
            if (item) {
              block.blockItems = item.blockItems;
            }

            if (block.offerBlockItems) {
              block.blockItems = [
                ...(block.blockItems || []),
                ...block.offerBlockItems.filter(
                  blockItem => blockItem && blockItem.editOnOffer
                )
              ];
            }
          });
        }
        setBlockItemsLoading(false);
      }

      setOffer(newOffer);
    }
  };

  const saveOfferCustomer = async (zoffer: IOffer, params?: string, isSelfSent:boolean=false) => {
    if (!zoffer.customer || !customerHasChanged) {
      saveOrUpdateOffer(zoffer,params,isSelfSent);
      return;
    }
    if (
      zoffer.customer &&
      zoffer.customer.email &&
      isValidEmail(zoffer.customer.email)
    ) {

      zoffer.customer = await onSaveCustomer(zoffer.customer);
    }

    saveOrUpdateOffer(zoffer,params,isSelfSent);
  };

  const saveAsDraftHandler = () => {
    const newOffer = { ...offer };
    newOffer.status = EOfferStatus.DRAFT;
    saveOfferCustomer(newOffer);
  };

  const sendOfferHandler = () => {
    const newOffer = { ...offer };
    newOffer.status = EOfferStatus.SENT;
    setSendDialogOpen(false);
    saveOfferCustomer(newOffer);
  };


  const selfSendOfferHandler = () => {
    const newOffer = { ...offer,status:EOfferStatus.SELFSENT };
    //console.log("SELF",newOffer);
    //setSendDialogOpen(false);

    saveOfferCustomer(newOffer,"selfsent=email",true);
  };


  const editHandler = (pageView: EPageView) => {
    setConfirmDialog({
      onOk: () => {
        viewClickHandler(pageView);
        setEdit(true);
        setConfirmDialog(defaultConfirmDialog);
      },
      isOpen: true,
      title: "Are you sure?",
      content: "Editing the proposal is permanent."
    });
  };

  const goToNextLocation = (redirect: string) => {
    setSaved(true);
    if(nextLocation) {
      history.push(nextLocation);
    } else {
      history.push(redirect);
    }
    setSaved(false);
  }

  const saveOrUpdateOffer = async (newOffer: IOffer, params?: string,isSelfSent:boolean=false) => {
    let offer:IOffer=null;
    if (isUpdate) {
      offer=await onUpdateOffer(id, newOffer, params);

    } else {
      offer=await onSaveOffer(newOffer);
    }
    setSaved(true);
    if(isSelfSent) {
      history.push(`/offers/${offer.id}/sendemail`);
    }
    else {
      if (nextLocation) {
        history.push(nextLocation);
      } else {
        history.push(`/offers/${EPageView.FUNNEL}`);
      }
    }
    //console.log("HEP",offer,params);
    // Copy to Email
    /*
    if(offer!==null && params && params.includes("selfsent=email")) {
      const linkText:{[id:string]:string}={
        "en": "Click here to open the proposal",
        "se": "Klicka här för att öppna förslaget",
        "de": "Öffnen Sie das Angebot",
        "es": "Abre la propuesta haciendo clic aquí",
        "da": "Åbn oplægget ved at klikke her",
        "fr": "Ouvrez la proposition en cliquant ici",
        "nl": "Klik hier om het voorstel te bekijken",
        "fi": "Avaa tarjous klikkaamalla tästä",
        "pt": "Abra a proposta clicando aqui",
      };
    
      const lang=(offer.customer && offer.customer.language) || "en";
      const langLink=linkText[lang] || linkText["en"];

      const cc=(offer.recipients || []).join();

      const body:string=(offer.email?offer.email.body:"").replace(/\n/g,"<br/>");
      //      copyEmailToClipboard('<pre style="background-color:unset;font-family:inherit;">'+(offer.email?offer.email.body:"")+'<br/><br/><a style="color:rgb(52,152,219);" href="'+offer.link+'" target="_blank">'+langLink+'</a></pre>')
      copyEmailToClipboard('<span><span style="background-color:unset;">'+body+'</span><br/><br/><a style="color:rgb(52,152,219);background-color:unset;" href="'+offer.link+'" target="_blank">'+langLink+'</a></span>')

      window.open("mailto:"+(offer.customer.email || "")+"?subject="+(offer.email?offer.email.title:"")+(cc?"&cc="+cc:"")+"&body=paste email content from your clipboard (Windows: Ctrl+V or MacOS: Command+V)");
    }
      */


  };

  const copyOffer = async () => {
    setConfirmDialog({
      onOk: async () => {
        setInitDone(false);
        const newOffer = { ...offer };
        const copyOffer = await onCopyOffer(newOffer);
        setPageView(EPageView.EDIT);
        setConfirmDialog(defaultConfirmDialog);
        goToNextLocation(`/offers/${copyOffer.id}/${EPageView.EDIT}`);
      },
      isOpen: true,
      title: "Are you sure?",
      content: "Do you want to copy the proposal?"
    });
  };

  const deleteOffer = () => {
    setConfirmDialog({
      onOk: async () => {
        await onDeleteOffer(offer.id);
        setConfirmDialog(defaultConfirmDialog);
        goToNextLocation(`/offers/${EPageView.FUNNEL}`);
      },
      isOpen: true,
      title: "Are you sure?",
      content: "Deleting the proposal is permanent."
    });
  };

  const onAddBlockItem = async (id: string, blockId: string, editingStyle: EBlockEditingStyle) => {

    switch(editingStyle) {
      case EBlockEditingStyle.LIST:
        setBlockItemModal({
          open: true,
          id,
          blockId,
          blockItem: null
        });
        break;
      case EBlockEditingStyle.INLINE:
        const newOffer = { ...offer };
        const blocks = getBlocksObject(
          newOffer.userTemplate,
          EUserTemplateBlockType.DYNAMIC
        );

        setBlockLoading(blockId);
        const dbBlock = await onGetBlock(blockId);

        const block = blocks[id];
        const newBlockItem = { editOnOffer: true, id: uuid(), name: '', content: dbBlock.content };
        const newOfferBlockItems = [...(block.offerBlockItems || []), newBlockItem];
        block.offerBlockItems = newOfferBlockItems;
        block.blockItems = [...(block.blockItems || []), newBlockItem];
        
        setOffer(newOffer);
        setBlockLoading("");
        break;
      default:
    }
  };

  const onEditBlockItem = (id: string, blockItem: IBlockItem) => {
    setBlockItemModal({
      open: true,
      id,
      blockId: null,
      blockItem
    });
  };

  const markOfferAcceptedHandler = async (statusDetails: string) => {
    const data = {
      id: offer.id,
      statusDetails
    };
    const newOffer = await onMarkOfferAccepted(data);
    
    setOffer({ ...offer, ...newOffer });
  };

  const toggleArchiveHandler = async () => {
    const newOffer = await onArchiveOffer(offer.id, !offer.archived);
    
    setOffer({ ...offer, archived: newOffer.archived });
  };

  const terminateOfferHandler = async () => {
    const data = {
      id: offer.id
    };
    const newOffer = await onTerminateOffer(data);
    
    setOffer({ ...offer, ...newOffer });
  };

  /*
  const saveBlockItemHandler = async (
    id: string,
    blockId: string,
    blockItem: IBlockItem
  ) => {
    const newOffer = { ...offer };
    const blocks = getBlocksObject(
      newOffer.userTemplate,
      EUserTemplateBlockType.DYNAMIC
    );
    const block = blocks[id];

    if (blockId) {
      // Save new

      if (blockItem.content.length !== 0) {
        blockItem = await onSaveBlockItem(blockId, blockItem);
        block.offerBlockItems = [...(block.offerBlockItems || []), blockItem];
        block.blockItems = [...(block.blockItems || []), blockItem];
      }
    } else {
      // Edit

      // FIXME: adds duplicates to select
      if (blockItem.editOnOffer) {
        block.offerBlockItems =
          block.offerBlockItems &&
          block.offerBlockItems.map(item =>
            item.id === blockItem.id ? blockItem : item
          );
        block.blockItems =
          block.blockItems &&
          block.blockItems.map(item =>
            item.id === blockItem.id ? blockItem : item
          );
      } else {
        const newBlockItem = { ...blockItem, editOnOffer: true, id: uuid() };
        const index = block.offerBlockItems.findIndex(
          item => item.id === blockItem.id
        );
        const newOfferBlockItems = [...block.offerBlockItems];
        newOfferBlockItems[index] = newBlockItem;
        block.offerBlockItems = newOfferBlockItems;
        //block.offerBlockItems = [
        //  ...block.offerBlockItems.filter(item => item.id !== blockItem.id),
        //  newBlockItem
        //];
        block.blockItems = [...block.blockItems, newBlockItem];
      }
    }
    setOffer(newOffer);
    setBlockItemModal(defaultBlockItemModal);
  };
  */

  const blockItemChangedHandler = async (id: string, blockItem: IBlockItem) => {
    const newOffer = { ...offer };
    const blocks = getBlocksObject(
      newOffer.userTemplate,
      EUserTemplateBlockType.DYNAMIC
    );
    const block = blocks[id];

    if (blockItem.editOnOffer) {
      block.offerBlockItems =
        block.offerBlockItems &&
        block.offerBlockItems.map(item =>
          item.id === blockItem.id ? blockItem : item
        );
      block.blockItems =
        block.blockItems &&
        block.blockItems.map(item =>
          item.id === blockItem.id ? blockItem : item
        );
		} else {
      const newBlockItem = { ...blockItem, editOnOffer: true, id: uuid() };

      let newOfferBlockItems = [];

      if (block.offerBlockItems) {
        newOfferBlockItems = [...block.offerBlockItems];

        const index = block.offerBlockItems.findIndex(
          item => item.id === blockItem.id
        );

        if (index !== -1) {
          newOfferBlockItems[index] = newBlockItem;
        } else {
          newOfferBlockItems.push(newBlockItem);
        }
      } else {
        newOfferBlockItems.push(newBlockItem);
      }

      let newBlockItems = [];

      if (block.blockItems) {
        newBlockItems = [...block.blockItems, newBlockItem];
      } else {
        newBlockItems.push(newBlockItem);
      }

      block.offerBlockItems = newOfferBlockItems;
      block.blockItems = newBlockItems;
    }
    setOffer(newOffer);
    setBlockItemModal(defaultBlockItemModal);
  };

  const commentOfferHandler = async (comment: IOfferComment) => {
    const data = {
      id: offer.id,
      comment
    };
    const newOffer = await onCommentOffer(data);
    
    setOffer({
      ...offer,
      comments: newOffer.comments
    });
  };

  const saveDraftText = isUpdate ? "Update draft" : "Save as draft";

  let comments = null;
  if (offer) {
    comments = offer.comments;
  }

  const offerDone = isOfferDone(offer);

  const isValid = (): boolean => {
    if (!offer) return false;
    const { customer, userTemplate } = offer;

    const multipleSignatures = getOfferSigners(offer).filter((ob:ISignature)=>ob.signerType!==ESignerType.USER);
    const validSigners = (multipleSignatures.length > 0 && multipleSignatures.reduce((resp,ob)=>{
      return resp && isValidEmail(ob.email);
    },true)) || (multipleSignatures.length == 0 && customer && customer.email && isValidEmail(customer.email) && customer.name);

    
    return customer &&
      //customer.email &&
      //isValidEmail(customer.email) &&
      validSigners &&
      userTemplate &&
      userTemplate.id &&
      offer.expirationDate &&
      stringToDate(offer.expirationDate) > new Date()
      ? true
      : false;
  };

	// REMOVE UNWANTED DATA FROM PROPOSAL
	const removeUnwanted = (blocks:IUserTemplateAreaBlock[]) => {
		if(blocks) {
			blocks.forEach((block:IUserTemplateAreaBlock) => {
				if(block.content) {
					block.content.forEach((content:any)=>{
						if(content.contentType==="PRODUCTS") {
							content.products=content.products.filter((ob:IProduct)=>!ob.library)
						}
					})
				}
			})
		}
	}

	const openSendDialog = () => {
		removeUnwanted(offer.userTemplate.header.blocks)
		removeUnwanted(offer.userTemplate.main.blocks)
		removeUnwanted(offer.userTemplate.footer.blocks)

    if (offer.recipients && offer.recipients.length > 0) {
      let recipients = Array<string>();
      offer.recipients.forEach(recipient => {
        if (recipient && recipient.trim() !== "" && isValidEmail(recipient))
          recipients.push(recipient);
      });
			const newOffer = { ...offer, recipients };
      
      setOffer(newOffer);
    }
    setSendDialogOpen(true);
  };

  // Called when reloading or closing tab
  useEffect(() => {
    if (isEdit) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isEdit]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  // Called when routing in edit mode
  const handleNavigation = (location: any) => {
    // console.log(location);
    if (nextLocation || !isEdit || saved) {
      window.onbeforeunload = undefined;
      return true;
    }
    if (
      !location.pathname.startsWith("/offers/") ||
      location.pathname === `/offers/${EPageView.FUNNEL}`
    ) {
      setDialogOpen(true);
      setNextLocation(location.pathname);
      return false;
    }
    return true;
  };

  return offersLoading || initLoading ? (
    <Spinner color="primary" />
  ) : offersError ? (
    <Alert color="danger">{offersError}</Alert>
  ) : (
    <React.Fragment>
      {/*<Prompt message={handleNavigation} />*/}
      <CustomDialog
        open={dialogOpen}
        title={"Are you sure?"}
        okButtonText="Save"
        cancelButtonText="Discard"
        onCancel={() => {
          // console.log("nextLocation", nextLocation);
          if (nextLocation) {
            history.push(nextLocation);
          }
          setDialogOpen(false);
        }}
        onOk={() => {
          saveAsDraftHandler();
          setDialogOpen(false);
        }}
        noToggle
      >
        Do you want to save changes?
      </CustomDialog>

      <OfferBlockItemModal
        blockItemModal={blockItemModal}
        closeSidebar={() => setBlockItemModal(defaultBlockItemModal)}
        onSave={blockItemChangedHandler}
      />

      <CustomDialog
        loading={false}
        onOk={confirmDialog.onOk}
        okButtonText={"OK"}
        onCancel={() => setConfirmDialog(defaultConfirmDialog)}
        open={confirmDialog.isOpen}
        title={confirmDialog.title}
        isValid={true}
      >
        {confirmDialog.content}
      </CustomDialog>

      <OfferSendDialog
        isOpen={sendDialogOpen || type===EPageView.SENDEMAIL}
        viewType={type}
        setIsOpen={setSendDialogOpen}
        onSend={sendOfferHandler}
        onSelfSend={selfSendOfferHandler}
        offer={offer}
        isValid={isValid()}
        history={history}
      />

      <OfferContext.Provider
        value={{
          offer,
          setOffer:setOfferAfterInit,
          onDynamicBlockItemChanged,
          onSimpleContentChanged,
          blockItemsLoading,
          userTemplates,
          userTemplatesLoading,
          customers,
          customersLoading,
          onAddBlockItem,
          onEditBlockItem,
          customerHasChanged,
          setCustomerHasChanged,
          hideEditableContent,
          setHideEditableContent,
          onBlockItemChanged: blockItemChangedHandler,
          blockLoading,

          onSearchCustomers,
          onGetCustomer,
          currentUser,
        }}
      >
        <OfferEdit isVisible={type === EPageView.EDIT} />

        <ContentWithSidebarStatic isVisible={type === EPageView.PREVIEW}>
          <OfferPreview offer={offer} isVisible={true} currentUser={currentUser} />
          <SidebarStatic shadow mobileMargin>
            {isEdit ? (
              <OfferMessagingSidebar loading={emailLoading} />
            ) : (
              <OfferCommentsContext.Provider
                value={{
                  comments,
                  loading: commentLoading,
                  error: commentError,
                  defaultSenderName: null,
                  currentUser,
                  onCommentOffer: commentOfferHandler,
                  hideControls: offerDone || currentUser.isRestricted
                }}
              >
                <OfferPreviewSidebar
                  offer={offer}
                  offerDone={offerDone}
                  view={view}
                />
              </OfferCommentsContext.Provider>
            )}
          </SidebarStatic>
        </ContentWithSidebarStatic>
      </OfferContext.Provider>

      <FooterFixed mobile={!offerDone ? true : null}>
        {!currentUser.isRestricted && (
          <OfferFooter
            offer={offer}
            offerDone={offerDone}
            pageView={pageView}
            onViewChanged={viewClickHandler}
            onSaveDraft={saveAsDraftHandler}
            onSave={openSendDialog}
            onEdit={editHandler}
            saveDraftText={saveDraftText}
            status={offer.status}
            isEdit={isEdit}
            onCopy={copyOffer}
            onDelete={deleteOffer}
            isUpdate={isUpdate}
            onMarkOfferAsAccepted={markOfferAcceptedHandler}
            onToggleArchiveOffer={toggleArchiveHandler}
            onTerminateOffer={terminateOfferHandler}
            integrations={organization && organization.integrations}
          />
        )}
      </FooterFixed>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    organization: state.organizations.organization,
    customersLoading: state.customers.loading,
    customers: state.customers.customers,
    customer: state.customers.customer,
    userTemplates: state.userTemplates.userTemplates,
    userTemplatesLoading: state.userTemplates.loading,
    offersLoading: state.offers.loading,
    offersError: state.offers.error,
    commentError: state.offers.commentError,
    commentLoading: state.offers.commentLoading,
    currentUser: state.auth.currentUser,
    emailLoading: state.offers.emailLoading
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindCustomers: () => dispatch(actions.findCustomers()),
    onFindUserTemplates: () => dispatch(actions.findUserTemplates()),
    onFindBlockItems: id => dispatch(actions.findBlockItems(id, null)),
    onGetBlockItem: id => dispatch(actions.getBlockItem(id)),
    onSaveBlockItem: (blockId, blockItem) => dispatch(actions.saveBlockItem(blockId, blockItem)),
    onSaveOffer: offer => dispatch(actions.saveOffer(offer)),
    onUpdateOffer: (id, offer, params) => dispatch(actions.updateOffer(id, offer, params)),
    onGetOffer: id => dispatch(actions.getOffer(id, null, null)),
    onGetBlock: (id) => dispatch(actions.getBlock(id)),
    onGetUserOrganization: () => dispatch(actions.getUserOrganization()),
    onMarkOfferAccepted: data => dispatch(actions.markOfferAccepted(data)),
    onCommentOffer: data => dispatch(actions.commentOffer(data)),
    onArchiveOffer: (id, archived) =>
      dispatch(actions.archiveOffer(id, archived)),
    onSaveCustomer: customer => dispatch(actions.saveCustomer(customer)),
    onDeleteOffer: id => dispatch(actions.deleteOffer(id)),
    onCopyOffer: offer => dispatch(actions.copyOffer(offer)),
    onTerminateOffer: data => dispatch(actions.terminateOffer(data)),

    onSearchCustomers: queryString =>
      dispatch(actions.searchCustomers(queryString)),
    onGetCustomer: id => dispatch(actions.getCustomer(id)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
