import axios from 'axios';
import moment from 'moment-timezone';

const hostname = window && window.location && window.location.hostname;

let apiPath = '/';
let withCredentials = false;
if(hostname === 'localhost') {
    apiPath = 'http://localhost:8081/';
    withCredentials = true;
}

const instance = axios.create({
    baseURL: apiPath,
    withCredentials: withCredentials,
  //timeout:1000*30,
});

instance.interceptors.request.use(config => {
    const email = localStorage.getItem('email');
    const accessToken = localStorage.getItem('accessToken');

    const embeddedAuth = window.embeddedAuth;

    // not in use??
    config.headers['X-Auth-Email'] = email ? email : '';
    config.headers['X-Auth-AccessToken'] = accessToken ? accessToken : '';

	config.headers['X-Auth-Embedded'] = embeddedAuth ? embeddedAuth : '';

    config.headers['Timezone'] = moment.tz.guess();

    return config;
});


instance.interceptors.response.use(config => config, ({ response }) => {
    if(!response || response.status === 500 || response.status === 405) {
        response.data = 'Server error! Please contact support.';
    } 
		else if(response.status === 403 && !(
			window.location.pathname==="/login"
			|| window.location.pathname==="/forgot"
			|| window.location.pathname==="/register"
			|| window.location.pathname==="/offer"
			|| window.location.pathname==="/"
			|| window.location.pathname==="/reset-password"
			|| window.location.pathname==="/vismasign/"
			|| window.location.pathname==="/strongauthentication/"
			|| window.location.pathname==="/strongauthentication/signed/"
			|| window.location.pathname.startsWith("/embedded/")


		)) {
			window.location.href="/login";
		}
    return Promise.reject({ response });
});

export default instance;
