import React, { useContext, useState, useEffect } from "react";

import CustomSelect from "../../../../../../../components/UI/CustomSelect/CustomSelect";
import Search from '../../../../../../../components/UI/Search/Search';

import OfferContext from "../../../../../containers/Offers/Offer/OfferContext";
import { FormGroup, Label, Input } from "reactstrap";
import { languages } from "../../../../../../../shared/languages";
import { ICustomer } from "../../../../../../../interfaces";
import { isValidEmail } from "../../../../../../../shared/utility";
import { ELanguage, ESearchType } from "../../../../../../../enums";
//import PipedriveLogo from "../../../../../../../components/UI/PipedriveLogo/PipedriveLogo";

import { faAddressBook, faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./OfferCustomer.module.scss";
import inputClasses from "../../../../../../../components/UI/Input/Input.module.scss";

import embeddedClasses from "../../../../../containers/Embedded/Embedded.module.scss";

interface IProps {
  data?:any;
  embedded?: any;
}

const defaultCustomer: ICustomer = {
  id: null,
  name: null,
  address: null,
  zip: null,
  city: null,
  phone: null,
  email: null,
  contactPerson: null,
  businessID: null,
  language: null,
  pipedrivePersonId: null,
  pipedriveOrganizationId: null,
  isPipedrive: false
};

const OfferCustomer: React.FC<IProps> = ({
  data,
  embedded,
}) => {
  const {
    offer,
    setOffer,
    //customers, 
    customersLoading,
    customerHasChanged,
    setCustomerHasChanged,
    onSearchCustomers,
    onGetCustomer, 
  } = useContext(OfferContext);

  const [customer, setCustomer] = useState<ICustomer>(defaultCustomer);
  //const [init, setInit] = useState(true);

  useEffect(() => {
    //if (!init) return;
    //console.log("--",offer.customer);

    if(offer && offer.customer) { // 1 === 1 //init && offer.customer && customer.id===null) {
      setCustomer(offer.customer);

      //      console.log("SET CUSTOMER1",offer.customer);
      //      console.log("CUSTOMER",customer);

      //setInit(false);
    }

    //console.log("1",customer);
    //console.log("2",offer.customer);
      /*
    else if(offer.customer && offer.customer.name!==customer.name) {
      if (offer.customer) {
        setCustomer(offer.customer);
        console.log("SET CUSTOMER2",offer.customer);
      }
    }
       */
  }, [offer]); //.customer]); //[init, offer.customer]);
  /*
  useEffect(() => {
    if(offer.customer && offer.customer.name!==customer.name) {
    if (offer.customer) {
      setCustomer(offer.customer);
      console.log("SET CUSTOMER2",offer.customer);

    }
    }
  }, [offer.customer]);
   */
/*
  const getCustomerLabel = (name: string, email: string) => {
    let label = email;
    if (name && email) {
      label = `${name} (${email})`;
    }
    return label;
  };
*/
  
/*
  const getCustomerOptions = () => {
    return (
      (customers &&
        sortByLabel(customers.map(
          ({ name, email, isPipedrive, id, pipedrivePersonId }) => ({
            id: id || pipedrivePersonId,
            label: getCustomerLabel(name, email),
            image: isPipedrive ? <PipedriveLogo show /> : null
          })
        ))) || []
    );
  };
*/
  const onCustomerChanged = (name: string, value: any) => {
    const newCustomer = { ...customer, [name]: value };
    setCustomer(newCustomer);
  };

  const onCustomerLeave = (name: string, value: any) => {
    let newCustomer = { ...customer, [name]: value };
    /*
    if (name === "name" && customers && customers.length > 0) {
      const findCustomer = customers.find(customer => {
        return customer.name === value;
      });

      if (findCustomer) {
        newCustomer = { ...findCustomer };
        setCustomer(newCustomer);
      } else {
        newCustomer.id = null;
        newCustomer.pipedrivePersonId = null;
        newCustomer.pipedriveOrganizationId = null;
        newCustomer.isPipedrive = false;
        setCustomer(newCustomer);
      }
    }
    */
    setOffer({ ...offer, customer: { ...newCustomer } });
    if(!customerHasChanged) setCustomerHasChanged(true);
  };
  
  const onClickCustomer = async (customer_id: string) => {
    const _c = await onGetCustomer(customer_id);
    setCustomer(_c);
    setOffer({ ...offer, customer: _c });
  }

  const [showCustomer, setShowCustomer] = useState<boolean>(true);

	return (
    <React.Fragment>
      
      <div className={classes.CustomerDetails}>
        {embedded && data && data.application === "salesforce" 
          ? <div style={{"width":"100%"}} className={embeddedClasses.blockHeader} onClick={()=>setShowCustomer(!showCustomer)}>
              <div className={embeddedClasses.headerIcon}><FontAwesomeIcon icon={faAddressBook} /></div>Customer Details
              <div className={embeddedClasses.headerRightIcon}><FontAwesomeIcon icon={showCustomer?faCaretDown:faCaretRight} /></div>
          </div>
          : <h5 className={classes.Title}>Customer Details</h5>        
        }
        
        {!embedded &&
        <div className={classes.Select}>
          <Search
            type={ESearchType.CONTACTS}
            onChange={(queryString:string)=>onSearchCustomers(queryString)}
            onClick={(id:string, _c:any)=>{
              onClickCustomer(id);
            }}
          />
        </div>
        }

        {/*customers && <div className={classes.Select}>
          <CustomSelect
            className={inputClasses.Input}
            value={customer && (customer.id || customer.pipedrivePersonId)}
            onChange={(value: string) => {
              const _c=customers.find(customer => {
                  return customer.id === value || customer.pipedrivePersonId === value;
                });
              setCustomer(_c);
              setOffer({ ...offer, customer: _c });
            }}
            onBlur={() => {
              // moved to onChange because of ipad touchscreen events happens at same time
              //setOffer({ ...offer, customer: customer });
            }}
            options={getCustomerOptions()}
            isMulti={false}
            placeholder="Choose customer"
            loading={customersLoading}
          />
        </div>*/}

      </div>
      <div className={embedded && data && data.application === "salesforce"?(embeddedClasses.blockBody + " " +(!showCustomer?embeddedClasses.hideBlock:"")):""}>
        <FormGroup className={classes.Row}>
          <div className={classes.Left}>
            <Label for="customername-input" className={inputClasses.Label}>
              Customer name
            </Label>
            <Input
              autoComplete="new-password"
              className={inputClasses.Input}
              type="text"
              placeholder="Customer company name"
              value={(customer && customer.name) || ""}
              onChange={event => onCustomerChanged("name", event.target.value)}
              onBlur={() => onCustomerLeave("name", customer.name)}
              invalid={customer && (!customer.name)}
            />
          </div>
          <div className={classes.Right}>
            <Label for="business-input" className={inputClasses.Label}>
              Business ID
            </Label>
            <Input
              autoComplete="new-password"
              className={inputClasses.Input}
              type="text"
              placeholder="Business ID"
              value={(customer && customer.businessID) || ""}
              onChange={event =>
                onCustomerChanged("businessID", event.target.value)
              }
              onBlur={() => onCustomerLeave("businessID", customer.businessID)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="address-input" className={inputClasses.Label}>
            Address
          </Label>
          <Input
            autoComplete="new-password"
            className={inputClasses.Input}
            type="text"
            placeholder="Customer address or other needed details"
            value={(customer && customer.address) || ""}
            onChange={event => onCustomerChanged("address", event.target.value)}
            onBlur={() => onCustomerLeave("address", customer.address)}
          />
        </FormGroup>

        <FormGroup className={classes.Row}>
          <div className={classes.Left}>
          <Label for="zip-input" className={inputClasses.Label}>
            Postal code
          </Label>
          <Input
            autoComplete="new-password"
            className={inputClasses.Input}
            type="text"
            placeholder="Postal code"
            value={(customer && customer.zip) || ""}
            onChange={event => onCustomerChanged("zip", event.target.value)}
            onBlur={() => onCustomerLeave("zip", customer.zip)}
          />
        </div>
        <div className={classes.Right}>
          <Label for="city-input" className={inputClasses.Label}>
            City
          </Label>
          <Input
            autoComplete="new-password"
            className={inputClasses.Input}
            type="text"
            placeholder="City"
            value={(customer && customer.city) || ""}
            onChange={event => onCustomerChanged("city", event.target.value)}
            onBlur={() => onCustomerLeave("city", customer.city)}
          />
        </div>
        </FormGroup>


        <FormGroup className={classes.Row}>
          <div className={classes.Left}>
            <Label for="contact-input" className={inputClasses.Label}>
              Contact person
            </Label>
            <Input
              autoComplete="new-password"
              className={inputClasses.Input}
              type="text"
              placeholder="Person who should accept this proposal"
              value={(customer && customer.contactPerson) || ""}
              onChange={event => onCustomerChanged("contactPerson", event.target.value)}
              onBlur={event => onCustomerLeave("contactPerson", event.target.value)}
            />
          </div>
          <div className={classes.Right}>
            <Label for="email-input" className={inputClasses.Label}>
              Email *
            </Label>
            <Input
              autoComplete="new-password"
              className={inputClasses.Input}
              type="email"
              placeholder="Email of contact person"
              value={(customer && customer.email) || ""}
              onChange={event => onCustomerChanged("email", event.target.value)}
              onBlur={event => onCustomerLeave("email", event.target.value)}
              invalid={customer && (!customer.email || !isValidEmail(customer.email))}
            />
          </div>
        </FormGroup>
        <FormGroup className={classes.Row}>
          <div className={classes.Left}>
            <Label for="phone-input" className={inputClasses.Label}>
              Phone number
            </Label>
            <Input
              autoComplete="new-password"
              className={inputClasses.Input}
              type="tel"
              placeholder="Phone number in international format, e.g. +358401234567"
              value={(customer && customer.phone) || ""}
              onChange={event => onCustomerChanged("phone", event.target.value)}
              onBlur={() => onCustomerLeave("phone", customer.phone)}
            />
          </div>
          <div className={classes.Language}>
            <Label className={inputClasses.Label}>Language</Label>
            <CustomSelect
              styles={{
                control: (base) => {
                  return {
                    ...base,
                    minHeight: "0",
                    height: "calc(1.5em + 0.75rem + 2px)",
                  }
                },
                singleValue: (base) => {
                  return {
                    ...base,
                    top: "45%"
                  }
                },
                placeholder: (base) => {
                  return {
                    ...base,
                    top: "45%"
                  }
                }
              }}
              className={inputClasses.Input}
              value={(customer && customer.language) || null}
              onChange={(value: string) => {
                onCustomerChanged("language", value as ELanguage);
                onCustomerLeave("language", value as ELanguage);
              }}
              options={languages}
              isMulti={false}
              placeholder="Choose language"
              loading={customersLoading}
            />
            {/*
              onChange={(value: string) => onCustomerChanged("language", value as ELanguage)}
              onBlur={() => onCustomerLeave("language", customer.language)}
              */}
          </div>
        </FormGroup>
      </div>
    </React.Fragment>
  );
};

export default OfferCustomer;
