import React from 'react';

import FormContainer from '../../../../../../components/UI/FormContainer/FormContainer';
import OfferBasics from './OfferBasics/OfferBasics';
import OfferContent from './OfferContent/OfferContent';
import OfferCustomer from './OfferCustomer/OfferCustomer';
import OfferFromIntegration from './OfferFromIntegration/OfferFromIntegration';

import classes from "./OfferEdit.module.scss";

interface IProps {
  isVisible: boolean;
  integrationData?: any;
  embedded?: boolean;
}

const OfferEdit: React.FC<IProps> = ({ isVisible, integrationData, embedded }) => {
  const isDraft = integrationData && integrationData.offer && integrationData.offer.status !== "CONFIRMED" && integrationData.offer.id !== null;
  return (
    <FormContainer
      style={{ display: isVisible ? "flex" : "none" }}
      title={isDraft?"Edit Draft":"Create new proposal"}
      loading={false}
      error={null}
      containerClassName={classes.Container}
    >
      <OfferFromIntegration data={integrationData} embedded={embedded}/>
      <OfferCustomer data={integrationData} embedded={embedded}/>
      <OfferBasics />
      <OfferContent />
    </FormContainer>
  );
};

export default OfferEdit;
